import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

import Label from './Label';

import { HomeIcon, BurgerIcon } from '../util/icons';
import { colors } from '../util/theme';
import santa_hat from '../assets/santa_hat.png';

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: auto;
  padding: 40px 0 40px 0;
`;

const FlexRow = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: ${isMobile ? '24px' : '140px'};
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`;

const RightGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  margin-left: auto;
  margin-right: 140px;
`;

const NavigationLabel = styled(NavLink)`
  text-decoration: none;
  margin-left: ${isMobile ? '24px' : '0'};

  &:hover {
    cursor: pointer;
  }

  padding: 8px 0 8px 0;
`;

const MenuWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 71px;
  background-color: ${colors.white};
`;

const BurgerIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  flex-grow: 1;
  height: 100%;
`;

const BurgerMenu = styled.div`
  position: absolute;
  top: 71px;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  width: 100%;
  background-color: ${colors.white};
  border-top: 2px solid ${colors.lightGrey};
  border-bottom: 2px solid ${colors.lightGrey};
`;

const SantaHatWrapper = styled(NavLink)``;

const SantaHat = styled.img`
  position: relative;
  bottom: 15px;
  left: 35px;
  width: 30px;
  height: 30px;
`;

const navigation = {
  home: { label: 'Forside', nav: '/' },
  wish_list: { label: 'Ønske liste', nav: '/wish-list' },
  photos: { label: 'Foto album', nav: '/albums' },
  members: { label: 'Medlemmer', nav: '/members' },
  christmasRiddle: { label: '', nav: '/riddle' }
}

const NavigationBar = () => {
  const location = useLocation();
  const [ open, setOpen ] = useState(false);

  const today = new Date();
  const isDecember24th = today.getDate() === 24 && today.getMonth() === 11;

  return (
    <React.Fragment>
      <BrowserView>
        <Wrapper>
          <FlexRow to={navigation.home.nav}>
            {isDecember24th && 
              <SantaHatWrapper to={navigation.christmasRiddle.nav}>
                <SantaHat src={santa_hat} />
              </SantaHatWrapper>
            }
            <HomeIcon />
            <Label label={'fabomi.dk'} fontSize={26} fontWeight={800} style={{ marginLeft: 16, borderBottom: location.pathname === navigation.home.nav && `3px solid ${colors.green}` }} />
          </FlexRow>
          <RightGroup>
            <NavigationLabel to={navigation.wish_list.nav}>
              <Label label={navigation.wish_list.label} fontSize={26} fontWeight={800} style={{ marginRight: 40, borderBottom: location.pathname === navigation.wish_list.nav && `3px solid ${colors.green}` }} />
            </NavigationLabel>
            <NavigationLabel to={navigation.photos.nav}>
              <Label label={navigation.photos.label} fontSize={26} fontWeight={800} style={{ marginRight: 40, borderBottom: location.pathname === navigation.photos.nav && `3px solid ${colors.green}` }} />
            </NavigationLabel>
            <NavigationLabel to={navigation.members.nav}>
              <Label label={navigation.members.label} fontSize={26} fontWeight={800} style={{ marginRight: 40, borderBottom: location.pathname === navigation.members.nav && `3px solid ${colors.green}` }} />
            </NavigationLabel>
          </RightGroup>
        </Wrapper>
      </BrowserView>
      <MobileView>
        <div style={{ position: 'fixed', zIndex: 5, width: '100vw' }}>
        <MenuWrapper>
          <FlexRow to={navigation.home.nav} onClick={() => setOpen(false)}>
            {isDecember24th &&
              <SantaHatWrapper to={navigation.christmasRiddle.nav}>
                <SantaHat src={santa_hat} />
              </SantaHatWrapper>
            }
            <HomeIcon />
            <Label label={'fabomi.dk'} fontSize={26} fontWeight={800} style={{ marginLeft: 16 }} />
          </FlexRow>
          <BurgerIconContainer onClick={() => setOpen(!open)}>
            <BurgerIcon style={{ marginRight: 24 }} />
          </BurgerIconContainer>
          {open && <BurgerMenu>
            <NavigationLabel to={navigation.home.nav} onClick={() => setOpen(false)}>
              <Label label={navigation.home.label} fontSize={26} fontWeight={800} style={{ marginRight: 40 }} color={location.pathname === navigation.home.nav ? colors.green : colors.black} />
            </NavigationLabel>
            <NavigationLabel to={navigation.wish_list.nav} onClick={() => setOpen(false)}>
              <Label label={navigation.wish_list.label} fontSize={26} fontWeight={800} style={{ marginRight: 40 }} color={location.pathname === navigation.wish_list.nav ? colors.green : colors.black} />
            </NavigationLabel>
            <NavigationLabel to={navigation.photos.nav} onClick={() => setOpen(false)}>
              <Label label={navigation.photos.label} fontSize={26} fontWeight={800} style={{ marginRight: 40 }} color={location.pathname === navigation.photos.nav ? colors.green : colors.black} />
            </NavigationLabel>
            <NavigationLabel to={navigation.members.nav} onClick={() => setOpen(false)}>
              <Label label={navigation.members.label} fontSize={26} fontWeight={800} style={{ marginRight: 40 }} color={location.pathname === navigation.members.nav ? colors.green : colors.black} />
            </NavigationLabel>
          </BurgerMenu>}
        </MenuWrapper>
        </div>
        <div style={{  height: 71 }}/>
      </MobileView>
    </React.Fragment>
  );
}

export default NavigationBar;
