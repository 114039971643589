const SnowFall = () => {
  return (
    <div class="snowflakes" aria-hidden="true">
      {[...Array(12).keys()].map(() => (
        <div class="snowflake">
          <div class="inner">❅</div>
        </div>
      ))}
    </div>
  )
}

export default SnowFall;