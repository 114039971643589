import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from '../util/theme';

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 160px;
`;

const Binary = styled.code`
  background: #202020;
  padding: 8px;
  border-radius: 4px;
  color: white;
  max-width: 75vw;
  margin-bottom: 40px;
`;

const Inputfield = styled.input`
  width: 200px;
  height: 32px;

  outline: none;
  border: 1px solid #202020;
  border-radius: 8px;
  padding: 0;
  text-indent: 8px;
  color: ${colors.black};
  margin-top: 20px;
`;

const SuccessWrapper = styled.div`
  margin-top: 24px;
`;

const Link = styled.a``;

const ChristmasRiddle = () => {
  const [ password, setPassword ] = useState('');
  const [ success, setSuccess ] = useState(false);

  const validate = () => {
    if (btoa(password.toUpperCase()) === "RU5JR01B") {
      setPassword("Mysteriet er løst!")
      setSuccess(true);
    } else {
      setPassword("Forkert svar!");
      setTimeout(() => setPassword(""), 1500);
    }
  }

  return (
    <Wrapper>
      <Container>
        <h1>🎄 Jule Gåde 🔍</h1>
        
        <Binary>
          01010011 01110100 01110010 01100101 01100111 00100000 01010000 01110010 01101001 01101011 00100000 01010011 01110100 01110010 01100101 01100111
        </Binary>

        <Binary>
          0111 0 110 0 010 0010 1110 100 1 00 1 1011 000 101 0100 01 10 100<br/>
          11 0 10 100 010 0101 1000 1 00 0 10 110 0100 01 10 100<br/>
          0111 0 110 0101 10 100 010 0 010 11 00 110 100 01 110 0100 00 110 1<br/>
          11 0 10 101 01 10 01 0010 000 0100 1110 010 0 000 01 0010 11 00 10 0000 1011 0100 100 0 000 1
        </Binary>

        <Inputfield 
          type={'text'} 
          value={password} 
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              validate();
            }
          }}
        ></Inputfield>

        {success &&
          <SuccessWrapper>
            <Link href="https://www.enigma.dk/om/enigma">Enigma Museum</Link>
          </SuccessWrapper>
        }
      </Container>
    </Wrapper>
  );
}

export default ChristmasRiddle;